import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentPinInput.figmaUrl.android} codeUrl={checklists.componentPinInput.codeUrl.android} checklists={checklists.componentPinInput.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`The OTP (One-Time Password) component is a user interface element that allows users to enter a unique password for authentication or verification purposes.`}</p>
    <div {...{
      "className": "line",
      "style": {
        "backgroundColor": "white"
      }
    }}>{`
  `}<div parentName="div" {...{
        "className": "item-one",
        "align": "center"
      }}>{`
    `}<div parentName="div" {...{
          "style": {
            "padding": "7px 0"
          }
        }}>{`
      `}<img parentName="div" {...{
            "align": "center",
            "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/pininput/android-compose-pin-input.gif",
            "alt": "Legion Pin Input Android Compose"
          }}></img>{`
    `}</div>{`
  `}</div>
    </div>
    <div className="divi" />
    <h2>{`Variants`}</h2>
    <p>{`Legion has 3 variants of Pin Input :`}</p>
    <ol>
      <li parentName="ol">{`Pin Input Reguler`}</li>
      <li parentName="ol">{`Pin Input Error`}</li>
      <li parentName="ol">{`Pin Input Success`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <h3>{`Pin Input Reguler`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember {
    mutableStateOf("")
}

LgnPinInput(
    value = value,
    onOtpTextChange = { it, isFull ->
        value = it
    },
    pintCount = 4
)
`}</code></pre>
    <h3>{`Pin Input Error`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember {
    mutableStateOf("")
}

var message by remember {
    mutableStateOf("message")
}

LgnPinInput(
    value = value,
    onOtpTextChange = { it, isFull ->
        value = it
        message = ""
    },
    pintCount = 4,
    error = message
)
`}</code></pre>
    <h3>{`Pin Input Success`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var value by remember {
    mutableStateOf("")
}

var message by remember {
    mutableStateOf("message")
}

LgnPinInput(
    value = value,
    onOtpTextChange = { it, isFull ->
        value = it
        message = ""
    },
    pintCount = 4,
    success = message
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Attributes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameters`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Types`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Descriptions`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Value of Pin Input`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`colors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`LgnPinInputColors`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Color of Pin Input`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`shape`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Shape`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Shape of Pin Input`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`error`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Error Message of Pin Input`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Success Message of Rating`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onOtpTextChange`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Function`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set Action When OTP is Change`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      